/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
@import 'animations';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  color: #333333;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  background-color: #fff;
  color: #333333;
  // background: #0d153a url('/images/bg.jpg') no-repeat right center / cover;
}
.wrap {
  padding: 30px 0;
  overflow-x: clip;
}
.container-fluid {
  &.but-fixed {
    max-width: 1356px;
  }
  &.wider {
    max-width: 1800px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: $theme-primary !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
}
#header-wrap {
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
#header {
  // background: rgba(255, 255, 255, 0.8);
  a.logo {
    border: 0;
    width: 100%;
    max-width: 200px;
    min-width: 200px;
    img {
      width: auto;
      height: auto;
    }
    span.big,
    span.small {
      font-size: 1.5em;
      color: #555;
      line-height: 1em;
    }
    span.big {
      font-weight: 600;
      font-family: var(--font-family);
    }
    span.small {
      font-size: 16px;
      font-family: var(--font-family);
    }
  }
}
#footer-wrap {
  background-color: $theme-primary;
}
.footer {
  width: 100%;
  background-color: transparent;
  color: #fff;
  // border-top: 1px solid #e4e4e4;
  font-size: 1rem;
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: inherit;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  ul.links {
    padding-left: 0;
    list-style: none;
    li {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
  }
  a.social {
    height: 40px;
    width: 40px;
    margin: 0 5px;
    color: $theme-primary !important;
    background-color: rgba(255, 255, 255, 0.3);
    font-size: 19px;
  }
  a.social [class^='icon-']:before {
    line-height: 41px;
  }
  a.social:hover,
  a.social:active {
    font-size: 21px;
  }
}

/* --------------------------------------------------------------
    Patterned BGs
-------------------------------------------------------------- */
.bg-burst-pattern {
  background-image: url(/images/burst-pattern-bg.png);
  background-repeat: repeat;
  background-size: 150px;
  background-position: center center;
}
.bg-squiggly-outline-pattern {
  background-image: url(/images/squiggly-outline-pattern-bg.png);
  background-repeat: repeat;
  // background-size: 200px;
  background-position: center center;
}
.bg-squiggly-outline-dark-pattern {
  background-image: url(/images/squiggly-outline-dark-pattern-bg.png);
  background-repeat: repeat;
  // background-size: 200px;
  background-position: center center;
}
.bg-squiggly-filled-pattern {
  background-image: url(/images/squiggly-filled-pattern-bg.png);
  background-repeat: repeat;
  // background-size: 200px;
  background-position: center center;
}

#footer-cta-wrap {
  position: relative;
  color: #fff;
  // z-index: 1;
  .footer-cta-box {
    margin-bottom: -100px;
    background-color: $theme-secondary;
    // text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  }
}
#footer-cta-wrap + #footer-wrap {
  padding-top: 170px !important;
}
#banner-wrap {
  * {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }
  &.homepage {
    background: $theme-primary url(/images/certus-icon-bg.png) no-repeat;
    background-position: calc(100% + 50px) -100px;
    background-size: 400px;
  }
}
#image-carousel-wrap {
  position: relative;
  background-color: $theme-primary;
  .image-carousel {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    // transition: height ease-in-out 0.03s, opacity ease-in-out 0.03s;
    &.ready {
      max-height: 500px;
      opacity: 1;
    }
  }
  &:before,
  &:after {
    position: absolute;
    content: '';
    height: 70px;
    width: 100%;
    top: -1px;
    left: 0;
    z-index: 100;
    clip-path: ellipse(55% 60% at 50% 0%);
    background-color: $theme-primary;
    opacity: 0;
    transition: opacity ease-in-out 0.03s;
  }
  &:after {
    top: auto;
    bottom: -1px;
    clip-path: ellipse(55% 60% at 50% 100%);
  }
  &.ready {
    &:before,
    &:after {
      opacity: 1;
    }
  }
}
.vertical-card {
  background-color: $theme-primary;
  position: relative;
  overflow: hidden;
  .card-copy {
    z-index: 1;
  }
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  &.workplace {
    background: $theme-primary url(/images/carousel/clapping-workers.jpg) no-repeat 30% 20% / cover;
    &:before {
      background-color: $theme-primary;
      opacity: 0.9;
    }
  }
  &.technical {
    background: #0a2d50 url(/images/carousel/team-training.jpg) no-repeat 30% 30% / cover;
    &:before {
      background-color: #0a2d50;
      opacity: 0.95;
    }
  }
  &.professional {
    background: #045475 url(/images/carousel/team-planning.jpg) no-repeat 30% 10% / cover;
    &:before {
      background-color: #045475;
      opacity: 0.95;
    }
  }
  &.driver {
    background: #007393 url(/images/carousel/teen-driver.jpg) no-repeat 30% 30% / cover;
    &:before {
      background-color: #007393;
      opacity: 0.95;
    }
  }
}
.press-release-card {
  .thumbnail {
    display: block;
    overflow: hidden;
    img.zoom {
      transition: transform 0.2s;
    }
    &:hover {
      img.zoom {
        transform: scale(1.025);
      }
    }
  }
}
.copy-column {
  position: relative;
  z-index: 1;
}
.squiggly-graphic {
  position: relative;
  & > * {
    position: relative;
    z-index: 1;
  }
  &:before {
    display: none;
    width: 699px;
    height: 172px;
    position: absolute;
    content: url(/images/squiggly-graphic.svg);
    z-index: 0;
  }
  &.top-right {
    &:before {
      top: -100px;
      left: 25%;
    }
  }
  &.bottom-right {
    &:before {
      bottom: -105px;
      left: 0;
    }
  }
  &.bottom-left {
    &:before {
      bottom: -110px;
      right: -50px;
    }
  }
  &.bottom {
    &:before {
      bottom: -110px;
      left: -100px;
    }
  }
}
.burst-graphic {
  position: relative;
  & > * {
    position: relative;
    z-index: 1;
  }
  &:before {
    display: none;
    position: absolute;
    content: url(/images/burst-graphic.svg);
    z-index: 0;
  }
  &.top-left {
    &:before {
      top: -100px;
      left: -100px;
    }
  }
  &.top-right {
    &:before {
      top: -100px;
      right: -100px;
    }
  }
  &.bottom-left {
    &:before {
      bottom: -100px;
      left: -100px;
    }
  }
  &.bottom-right {
    &:before {
      bottom: -100px;
      right: -100px;
    }
  }
}
.bg-green-icon {
  background: $theme-primary url(/images/certus-icon-bg.png) no-repeat;
  background-position: calc(100% + 150px) 50%;
  background-size: 500px;
  .bg-green-icon-small {
    background-size: 500px;
  }
}
#case-studies-wrap {
  background-color: #f1f1f1;
}
.timeline-wrap {
  position: relative;
  & > * {
    z-index: 1;
  }
  &::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 50vw;
    top: 0;
    left: calc(-50vw + 15px);
    background-color: $theme-secondary-alt;
    z-index: 0;
  }
  .timeline-year {
    margin-bottom: 0;
    display: inline-block;
    position: relative;
    z-index: 1;
    &::after {
      position: absolute;
      content: '';
      left: -25px;
      bottom: -3px;
      width: calc(100% + 35px);
      height: 3px;
      background-color: $theme-secondary-alt;
    }
    // border-bottom: 3px solid $theme-secondary-alt;
  }
}
.burst-row-wrap {
  position: relative;
  & > * {
    z-index: 1;
  }
  &::before {
    display: none;
    position: absolute;
    content: '';
    height: 100%;
    width: 50vw;
    top: 0;
    left: calc(-50vw + 33.3333%);
    background-color: $theme-primary;
    z-index: 0;
  }
  .graphic {
    background-color: $theme-primary;
    background-image: url(/images/certus-icon-bg-vertical.png);
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: 500px;
    img {
      display: block;
      margin: 0 auto;
      max-width: 250px;
      transform: translateY(50%);
    }
  }
  &.featured-news {
    &::before {
      background-color: $theme-secondary-alt;
    }
    .graphic {
      background-color: $theme-secondary-alt;
    }
  }
  .copy {
    text-align: center;
  }
}
.slider-overflow-wrap {
  .tns-ovh {
    overflow: visible;
  }
  .tns-item {
    display: flex;
  }
  .slide-card {
    width: 100%;
    .slide-image {
      min-width: 200px;
      img {
        max-width: 200px;
      }
    }
  }
}
#brands-wrap {
  .brand-card {
    min-height: 100px;
    position: relative;
    .brand-logo {
      cursor: pointer;
      filter: grayscale(1) opacity(0.75);
      &:hover {
        filter: none;
      }
    }
    &:focus-visible{
      .brand-logo{
        filter: none;
      }
    }
    // p {
    //   display: none;
    //   opacity: 0;
    // }
    .brand-info {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      min-height: 100%;
      overflow: hidden;
      opacity: 0;
      background-color: #fff;
      z-index: 10;
      transform: translateY(-50%) translateX(-50%) scale(0);
      transition: opacity ease-in-out 0.5s, transform ease-in-out 0.3s;
      .brand-logo {
        text-align: left;
        filter: none;
      }
      &:focus-visible {
        outline: 2px solid $blue;
      }
    }
    &.is-active {
      // box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      // .brand-logo {
      //   text-align: left;
      //   filter: none;
      // }
      // p {
      //   opacity: 1;
      // }
      .brand-info {
        opacity: 1;
        transform: translateY(-50%) translateX(-50%) scale(1);
      }
    }
    // &:hover {
    //   .brand-logo {
    //     filter: none;
    //   }
    // }
  }
}
.pillars-slider-wrap {
  // margin-bottom: 200px;
  padding-bottom: clamp(200px, 50%, ((50% - 30px) / 2)) !important;
  position: relative;
  user-select: none;
  & + .pillar-image {
    position: relative;
    display: block;
    width: 400px;
    max-width: calc(100% - 30px);
    transform: translateY(-50%);
    margin-bottom: max(-40%, -150px);
  }
}
#news-banner-image {
  background-size: cover;
  background-position: center;
}
.leader-card {
  // transition: box-shadow ease-in-out 0.3s;
  // overflow: hidden;
  &:hover {
    cursor: pointer;
    // box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    .leader-photo {
      & > img {
        transform: scale(1.05);
      }
      &::after {
        opacity: 1;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      }
      &::before {
        opacity: 0.1;
        transform: translateY(-50%) translateX(-50%) scale(1);
      }
    }
  }
  .leader-photo {
    position: relative;
    max-width: 200px;
    & > img {
      position: relative;
      z-index: 1;
      transition: transform ease-in-out 0.2s;
      // border: 1px solid rgba(0, 0, 0, 0.15);
    }
    &::after {
      position: absolute;
      font-family: 'icons';
      content: '\e824';
      display: block;
      width: 45px;
      height: 45px;
      right: 10px;
      bottom: 10px;
      line-height: 45px;
      background-color: $theme-secondary-alt;
      border-radius: 50px;
      opacity: 1;
      transition: opacity ease-in-out 0.3s, box-shadow ease-in-out 0.3s;
      z-index: 1;
    }
    &::before {
      position: absolute;
      content: '';
      width: calc(100% + 100px);
      height: calc(100% + 100px);
      border-radius: 50rem;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%) scale(0);
      background-color: lighten($theme-primary-alt2, 20%);
      opacity: 0.1;
      transition: transform ease-in-out 0.3s;
      z-index: 0;
    }
  }
}
#find-course-form {
  .custom-select {
    border-width: 2px;
  }
  .submit-btn {
    border-width: 2px;
  }
}
#acton-form {
  width: 100%;
  max-width: 600px;
  min-height: 550px;
  .ao-form-field {
    border-radius: 0.375rem;
  }
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #header a.logo {
    max-width: 200px;
  }
  .squiggly-graphic,
  .burst-graphic {
    &:before {
      display: block;
    }
  }
  .image-column {
    img {
      min-width: 360px;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  // this adds an extra padding to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 1.5rem);
    padding-left: var(--bs-gutter-x, 1.5rem);
  }
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    max-width: 250px;
  }
  #banner-wrap {
    &.homepage {
      background-position: calc(100% + 50px) -100px;
      background-size: 500px;
    }
  }
  #image-carousel-wrap {
    &:before,
    &:after {
      height: 75px;
      clip-path: ellipse(52% 60% at 50% 0%);
    }
    &:after {
      clip-path: ellipse(52% 60% at 50% 100%);
    }
  }
  .bg-green-icon {
    background-position: calc(100% + 150px) 50%;
    background-size: 600px;
  }
  .timeline-wrap {
    &::before {
      left: calc(-50vw + 33.3333%);
    }
    .timeline-year {
      display: block;
      margin-bottom: calc(-1em - 1.7rem);
      text-align: right;
      transform: translateX(calc(-100% - 3rem));
      &::after {
        display: none;
      }
    }
    .month {
      padding-top: 1rem;
      display: inline-block;
      position: relative;
      &::before {
        position: absolute;
        content: '';
        top: -3px;
        left: calc(-3rem);
        width: calc(100% + 3rem);
        height: 3px;
        background-color: $theme-secondary-alt;
      }
    }
    .col-lg-6:not(:nth-of-type(1)) {
      .month {
        &::before {
          display: none;
        }
      }
    }
  }
  .burst-row-wrap {
    &::before {
      display: block;
      background-image: url(/images/certus-icon-bg-horizontal.png);
      background-repeat: no-repeat;
      background-position: 100% 50%;
      background-size: 260px;
    }
    .graphic {
      background: none !important;
      img {
        max-width: 250px;
        transform: translateY(0) translateX(50%);
      }
    }
    .copy {
      text-align: left;
      padding-left: calc(150px + 2rem) !important;
    }
  }
  .form-row-wrap {
    position: relative;
    & > * {
      z-index: 1;
    }
    &::before {
      position: absolute;
      content: '';
      height: 100%;
      width: 50vw;
      top: 0;
      left: calc(-50vw + 41.66666667%);
      background-color: $theme-primary;
      z-index: 0;
    }
  }
  #brands-wrap {
    .brand-card {
      min-height: 200px;
    }
  }
  .pillars-slider-wrap {
    margin-bottom: 0;
    padding-bottom: 30px !important;
    & + .pillar-image {
      position: absolute;
      right: calc(var(--bs-gutter-x) * 0.5);
      top: 50%;
      width: 35%;
      max-width: calc(100% - 30px);
      transform: translateY(-50%);
      margin-bottom: 0;
    }
  }
  .image-column {
    img {
      min-width: 400px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  // this reverts the extra padding added to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  #banner-wrap {
    &.homepage {
      background-position: calc(100% + 50px) -100px;
      background-size: 600px;
    }
  }
  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }

  #image-carousel-wrap {
    &:before,
    &:after {
      height: 75px;
      clip-path: ellipse(52% 70% at 50% 0%);
    }
    &:after {
      clip-path: ellipse(52% 70% at 50% 100%);
    }
  }
  .timeline-wrap {
    .timeline-year {
      margin-bottom: calc(-1em - 1.5rem);
    }
    .col-lg-6:not(:nth-of-type(1), :nth-of-type(2)) {
      .month {
        &::before {
          display: none;
        }
      }
    }
    .col-lg-6:where(:nth-of-type(2)) {
      .month {
        &::before {
          display: block !important;
          left: calc(-30vw);
          width: calc(100% + 30vw);
          z-index: 0;
        }
      }
    }
  }
  #brands-wrap {
    .brand-card {
      min-height: 250px;
    }
  }
  .pillars-slider-wrap {
    padding-bottom: 3rem !important;
  }
  #news-banner-image {
    min-height: 300px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #header a.logo {
    max-width: 280px;
  }
  #banner-wrap {
    &.homepage {
      background-position: calc(100% + 40px) -100px;
      background-size: 600px;
    }
  }
  #image-carousel-wrap {
    &:before,
    &:after {
      height: 75px;
      clip-path: ellipse(52% 80% at 50% 0%);
    }
    &:after {
      clip-path: ellipse(52% 80% at 50% 100%);
    }
  }
  .burst-row-wrap {
    &::before {
      background-size: 320px;
    }
    .graphic {
      img {
        max-width: 300px;
      }
    }
    .copy {
      padding-left: calc(200px + 2rem) !important;
    }
  }
  .pillars-slider-wrap {
    & + .pillar-image {
      width: 30%;
    }
  }
  #news-banner-image {
    min-height: 350px;
  }
}

// Extra large devices (large desktops, 1400px and up)
@media (min-width: 1400px) {
  #banner-wrap {
    &.homepage {
      background-position: calc(100% + 40px) 101%;
      background-size: 700px;
    }
  }
  #image-carousel-wrap {
    &:before,
    &:after {
      height: 75px;
      clip-path: ellipse(52% 80% at 50% 0%);
    }
    &:after {
      clip-path: ellipse(52% 80% at 50% 100%);
    }
  }
}

@media (min-width: 1600px) {
  #banner-wrap {
    &.homepage {
      background-position: 95% 101%;
      background-size: 700px;
    }
  }
  #image-carousel-wrap {
    &:before,
    &:after {
      height: 75px;
      clip-path: ellipse(52% 100% at 50% 0%);
    }
    &:after {
      clip-path: ellipse(52% 100% at 50% 100%);
    }
  }
}
@media (min-width: 1900px) {
  #banner-wrap {
    &.homepage {
      background-position: 80% 101%;
      background-size: 700px;
    }
  }
  #image-carousel-wrap {
    &:before,
    &:after {
      height: 75px;
      clip-path: ellipse(52% 100% at 50% 0%);
    }
    &:after {
      clip-path: ellipse(52% 100% at 50% 100%);
    }
  }
}
