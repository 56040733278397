@charset "UTF-8";
/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Custom Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Theme Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    CTA buttons
----------------------------------------------------------------------------- */
.text-theme-primary {
  color: #0b1239 !important;
}

.text-theme-primary-alt {
  color: #0a2d50 !important;
}

.text-theme-primary-alt2 {
  color: #045475 !important;
}

.text-theme-secondary {
  color: #46955e !important;
}

.text-theme-secondary-alt {
  color: #d8f793 !important;
}

.text-theme-danger {
  color: #fb4f16 !important;
}

.border-theme-secondary {
  border-color: #46955e !important;
}

/* ----------------------------------------------------------------------------
    Backgrounds
----------------------------------------------------------------------------- */
.bg-theme-primary {
  background-color: #0b1239 !important;
}

.bg-theme-primary-alt {
  background-color: #0a2d50 !important;
}

.bg-theme-primary-alt2 {
  background-color: #045475 !important;
}

.bg-theme-secondary {
  background-color: #46955e !important;
}

.bg-theme-secondary-alt {
  background-color: #d8f793 !important;
}

/* ----------------------------------------------------------------------------
    Font Icons
----------------------------------------------------------------------------- */
@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot?21099076");
  src: url("../fonts/icons.eot?21099076#iefix") format("embedded-opentype"), url("../fonts/icons.woff2?21099076") format("woff2"), url("../fonts/icons.woff?21099076") format("woff"), url("../fonts/icons.ttf?21099076") format("truetype"), url("../fonts/icons.svg?21099076#icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class^=icon-]:before,
[class*=" icon-"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-dribbble:before {
  content: "\e801";
}

.icon-instagram:before {
  content: "\e802";
}

.icon-behance:before {
  content: "\e803";
}

.icon-linkedin:before {
  content: "\e804";
}

.icon-facebook:before {
  content: "\e805";
}

.icon-twitter:before {
  content: "\e806";
}

.icon-gplus:before {
  content: "\e807";
}

.icon-pinterest:before {
  content: "\e808";
}

.icon-tumblr:before {
  content: "\e809";
}

.icon-down:before {
  content: "\e810";
}

.icon-left:before {
  content: "\e811";
}

.icon-right:before {
  content: "\e812";
}

.icon-up:before {
  content: "\e813";
}

.icon-left-small:before {
  content: "\e814";
}

.icon-right-small:before {
  content: "\e815";
}

.icon-up-small:before {
  content: "\e816";
}

.icon-down-small:before {
  content: "\e817";
}

.icon-down-thin:before {
  content: "\e818";
}

.icon-left-thin:before {
  content: "\e819";
}

.icon-right-thin:before {
  content: "\e820";
}

.icon-up-thin:before {
  content: "\e821";
}

.icon-down-arrow:before {
  content: "\e822";
}

.icon-left-arrow:before {
  content: "\e823";
}

.icon-right-arrow:before {
  content: "\e824";
}

.icon-up-arrow:before {
  content: "\e825";
}

.icon-menu:before {
  content: "\e826";
}

.icon-home:before {
  content: "\e827";
}

.icon-lock:before {
  content: "\e828";
}

.icon-phone:before {
  content: "\e829";
}

.icon-chat:before {
  content: "\e830";
}

.icon-mail:before {
  content: "\e831";
}

.icon-comment:before {
  content: "\e832";
}

.icon-at:before {
  content: "\e833";
}

.icon-star:before {
  content: "\e834";
}

.icon-search:before {
  content: "\e835";
}

.icon-zoom:before {
  content: "\e836";
}

.icon-cog:before {
  content: "\e837";
}

.icon-link:before {
  content: "\e838";
}

.icon-share:before {
  content: "\e839";
}

.icon-check:before {
  content: "\e840";
}

.icon-cancel:before {
  content: "\e841";
}

.icon-plus:before {
  content: "\e842";
}

.icon-minus:before {
  content: "\e843";
}

.icon-close:before {
  content: "\e844";
}

.icon-user:before {
  content: "\e845";
}

.icon-spinner:before {
  content: "\e846";
}

.icon-youtube:before {
  content: "\e847";
}

a.social {
  display: inline-block;
  height: 50px;
  width: 50px;
  margin: 5px;
  color: #fff !important;
  background-color: #ccc;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 22px;
  text-align: center;
  overflow: hidden;
  vertical-align: middle;
  border: 0 !important;
  border-radius: 30px;
  transition: all 0.15s linear 0s;
}

a.social [class^=icon-]:before {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 50px;
}

a.social:hover,
a.social:active {
  font-size: 24px;
  border-radius: 4px;
  /*transform: rotate(360deg);*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a.social.dribbble:hover,
a.social.dribbble:active {
  background-color: #ea4c89;
}

a.social.instagram:hover,
a.social.instagram:active {
  background-color: #49789d;
}

a.social.behance:hover,
a.social.behance:active {
  background-color: #1769ff;
}

a.social.facebook:hover,
a.social.facebook:active {
  background-color: #3f5495;
}

a.social.twitter:hover,
a.social.twitter:active {
  background-color: #5d87c4;
}

a.social.linkedin:hover,
a.social.linkedin:active {
  background-color: #2575b2;
}

a.social.gplus:hover,
a.social.gplus:active {
  background-color: #cc4436;
}

a.social.pinterest:hover,
a.social.pinterest:active {
  background-color: #c2142f;
}

a.social.tumblr:hover,
a.social.tumblr:active {
  background-color: #529ecc;
}

a.social.youtube:hover,
a.social.youtube:active {
  background-color: #ff0000;
}

/* open-sans-300 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-300.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-300.woff") format("woff"), url("../fonts/open-sans-v18-latin-300.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-300.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-regular.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-regular.woff") format("woff"), url("../fonts/open-sans-v18-latin-regular.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-regular.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-600.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-600.woff") format("woff"), url("../fonts/open-sans-v18-latin-600.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-600.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-600italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-600italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-600italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-600italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-600italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-700.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-700.woff") format("woff"), url("../fonts/open-sans-v18-latin-700.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-700.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-700italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-700italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-700italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-700italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-700italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* fjalla-one-regular - latin */
@font-face {
  font-family: "Fjalla One";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/fjalla-one-v8-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/fjalla-one-v8-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/fjalla-one-v8-latin-regular.woff2") format("woff2"), url("../fonts/fjalla-one-v8-latin-regular.woff") format("woff"), url("../fonts/fjalla-one-v8-latin-regular.ttf") format("truetype"), url("../fonts/fjalla-one-v8-latin-regular.svg#FjallaOne") format("svg"); /* Legacy iOS */
}
/* roboto-slab-regular - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-regular.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-regular.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-regular.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-regular.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* roboto-slab-600 - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-600.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-600.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-600.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-600.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* roboto-slab-700 - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-700.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-700.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-700.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-700.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* montserrat-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/montserrat-v25-latin-300.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-300.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/montserrat-v25-latin-300italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-300italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/montserrat-v25-latin-regular.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-regular.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/montserrat-v25-latin-italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/montserrat-v25-latin-500.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-500.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/montserrat-v25-latin-500italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-500italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/montserrat-v25-latin-600.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-600.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/montserrat-v25-latin-600italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-600italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/montserrat-v25-latin-700.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-700.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/montserrat-v25-latin-700italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-700italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/montserrat-v25-latin-800.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-800.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* material icons */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIcons-Regular.woff2") format("woff2");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* fallback */
@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIconsOutlined-Regular.otf") format("woff2");
}
.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* fallback */
@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIconsRound-Regular.otf") format("woff2");
}
.material-icons-round {
  font-family: "Material Icons Round";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* ----------------------------------------------------------------------------
    CSS Variable, needs to be set in :root
----------------------------------------------------------------------------- */
:root {
  --font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans";
  --font-family-headline: "Montserrat", var(--font-family);
}

/* ----------------------------------------------------------------------------
    Smooth Scrolling
----------------------------------------------------------------------------- */
@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
[id] {
  scroll-margin-top: 1rem;
}

/* ----------------------------------------------------------------------------
    Typography
----------------------------------------------------------------------------- */
body {
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 500;
  font-family: "Montserrat", var(--font-family);
  line-height: 1.4;
  color: #0b1239;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit !important;
  border: 0 !important;
}
h1 a:hover, h1 a:active,
h2 a:hover,
h2 a:active,
h3 a:hover,
h3 a:active,
h4 a:hover,
h4 a:active,
h5 a:hover,
h5 a:active,
h6 a:hover,
h6 a:active {
  text-decoration: none !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: inherit !important;
}

a:not(.img, .cta-btn) {
  color: #284793;
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: inherit;
}

a:hover:not(.img, .cta-btn),
a:active:not(.img, .cta-btn) {
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #335abb;
}

a.img:focus-visible {
  outline: 2px solid #316ce8;
  outline-offset: -2px;
  border-radius: 7px;
}

a.img:hover,
a.img:active {
  text-decoration: none;
}

a[href^="tel:"] {
  color: inherit;
  border: 0;
}

ul.nav,
ol.nav {
  margin-left: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}

.cfix:after,
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

#content,
#main_content,
.container,
.container-fluid,
.break_word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/* ----------------------------------------------------------------------------
    Forms Elements
----------------------------------------------------------------------------- */
.opacity-1 {
  opacity: 1 !important;
}

.display-none {
  display: none !important;
}

/* ----------------------------------------------------------------------------
    Buttons
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Tables
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Modals
----------------------------------------------------------------------------- */
.modal {
  backdrop-filter: blur(5px);
}

.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.modal-header .close {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  margin-top: -0.7rem;
}

.modal-body .btn-close {
  opacity: 1;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  text-align: center;
  z-index: 100;
  background-color: #ffffff;
  border-bottom-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

/* ----------------------------------------------------------------------------
  Dropdown List
----------------------------------------------------------------------------- */
.dropdown-list {
  margin: auto;
  width: auto;
  position: relative;
  box-sizing: border-box;
}
.dropdown-list ul.drop {
  margin: 0;
  padding: 0;
  list-style: none;
}
.dropdown-list li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.dropdown-list span.dropdown {
  display: block;
  margin: 0;
  padding: 0 10px;
  width: auto;
  height: 40px;
  line-height: 38px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #a1c4e5;
  cursor: pointer;
  overflow: hidden;
}
.dropdown-list span.dropdown.is-active {
  border-radius: 3px 3px 0 0;
}
.dropdown-list span.dropdown.is-active:after {
  content: "\e816";
}
.dropdown-list span.dropdown:after {
  float: right;
  margin-top: 0;
  margin-left: 10px;
  width: 15px;
  height: 100%;
  content: "\e817";
  color: ineherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}
.dropdown-list span.dropdown:hover, .dropdown-list span.dropdown:active {
  background: #f1f1f1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.dropdown-list span.is-active + .drop {
  max-height: 350px;
  border: 1px solid #a1c4e5;
  border-top: 0;
  opacity: 1;
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.dropdown-list a:hover {
  text-decoration: none;
  border-bottom: 0;
}
.dropdown-list .drop {
  display: block;
  float: left;
  margin: 0;
  width: 100%;
  max-height: 0;
  position: absolute;
  background: #fff;
  top: 40px;
  border-radius: 0 0 3px 3px;
  z-index: 10;
  overflow-y: auto;
  opacity: 0;
  transition: max-height 0.3s cubic-bezier(0, 0.6, 0, 1), opacity 0.3s cubic-bezier(0, 0.6, 0, 1);
}
.dropdown-list .drop li {
  float: none;
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  height: auto;
  border-top: 1px dotted #ccc;
}
.dropdown-list .drop li:first-child {
  border-top: 0;
}
.dropdown-list .drop li a {
  float: none;
  display: block;
  margin: 0;
  padding: 6px 10px 6px 25px;
  height: auto;
  width: auto;
  text-transform: none;
  color: inherit;
  background: #fff;
  text-align: left;
  border-radius: 0;
  border: 0;
  text-decoration: none;
}
.dropdown-list .drop li a:before {
  display: inline-block;
  margin-left: -15px;
  margin-right: 5px;
  width: 10px;
  height: inherit;
  content: "»";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}
.dropdown-list .drop li a:hover, .dropdown-list .drop li a:active {
  background: #f1f1f1;
}

ul,
ol {
  padding-left: 30px;
}

ul li,
ol li {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

ul.check,
ul.star,
ul.carrot {
  margin-left: 0;
  padding-left: 0;
  list-style: none outside;
  line-height: inherit;
}

ul.check li,
ul.star li,
ul.carrot li {
  padding-left: 1.3em;
  margin-left: 0;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  position: relative;
}

ul.check li li,
ul.star li li,
ul.carrot li li {
  padding-left: 0;
}

ul.carrot li {
  padding-left: 0.9em;
}

ul.check > li:before,
ul.star > li:before,
ul.carrot > li:before {
  display: block;
  margin-top: 0.1em;
  width: 1em;
  height: inherit;
  color: inherit;
  font-size: 0.9em;
  font-weight: normal !important;
  word-wrap: normal;
  position: absolute;
  left: 0;
}

ul.check > li:before {
  content: "\e840";
  font-family: "icons";
}

ul.star > li:before {
  content: "\e834";
  font-family: "icons";
}

ul.carrot > li:before {
  content: "»";
  font-family: "icons";
  margin-top: 0.2em;
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  width: 1em;
}

/* ----------------------------------------------------------------------------
  Accordion
----------------------------------------------------------------------------- */
.accordion-wrap {
  margin-bottom: 1rem;
}
.accordion-wrap .accordion {
  padding: 0;
  border-bottom: 1px dotted #ccc;
}
.accordion-wrap .accordion:first-child {
  border-top: 1px dotted #ccc;
}
.accordion-wrap .accordion .accordion-toggle {
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: calc(1em + 10px);
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  overflow: hidden;
}
.accordion-wrap .accordion .accordion-toggle:before {
  float: right;
  color: inherit;
  content: "\e817";
  font-family: "icons";
  font-size: 1em;
  font-weight: normal !important;
  height: inherit;
  width: 1em;
  margin-right: -1em;
  text-align: right;
}
.accordion-wrap .accordion .accordion-toggle:hover {
  background-color: #fafef2;
}
.accordion-wrap .accordion.is-open > .accordion-content {
  padding-top: 5px;
  opacity: 1;
  transform: scaleY(1);
}
.accordion-wrap .accordion.is-open > .accordion-toggle:before {
  content: "\e816";
}
.accordion-wrap .accordion .accordion-content {
  padding: 0 10px;
  max-height: 0;
  opacity: 0;
  transform-origin: top;
  transition: max-height 0.35s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
}

/* ----------------------------------------------------------------------------
  Bootstrap Overrides
----------------------------------------------------------------------------- */
b,
strong {
  font-weight: 600;
}

.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.modal-header h1,
.modal-header h2,
.modal-header h3,
.modal-header h4,
.modal-header h5 {
  margin-top: 0;
  font-weight: 400;
}

input[type=number] {
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  color: #e0e0e0 !important;
}

.custom-control.is-invalid .custom-control-input ~ .custom-control-label, .custom-control:invalid .custom-control-input ~ .custom-control-label {
  color: #fb4f16;
}
.custom-control.is-invalid .custom-control-input ~ .custom-control-label:before, .custom-control:invalid .custom-control-input ~ .custom-control-label:before {
  border-color: #fb4f16;
}
.custom-control.is-invalid .custom-control-input:focus ~ .custom-control-label:before, .custom-control:invalid .custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-control.custom-checkbox {
  padding-left: 1.75rem;
}
.custom-control.custom-checkbox .custom-control-label:before, .custom-control.custom-checkbox .custom-control-label:after {
  left: -1.75rem;
  width: 1.2rem;
  height: 1.2rem;
}
.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #316ce8;
  background-color: #316ce8;
}
.custom-control.custom-radio {
  padding-left: 1.75rem;
}
.custom-control.custom-radio .custom-control-label:before, .custom-control.custom-radio .custom-control-label:after {
  left: -1.75rem;
  width: 1.2rem;
  height: 1.2rem;
}
.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #316ce8;
  background-color: #316ce8;
}

.form-control.is-invalid {
  border-color: #fb4f16;
}
.form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  background-image: none;
}

.custom-select.form-control-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.custom-select.is-invalid {
  border-color: #fb4f16;
}
.custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #80bdff;
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
}

.table {
  word-wrap: normal;
  word-break: normal;
}
.table.align-middle th,
.table.align-middle td {
  vertical-align: middle;
}
.table.table-sm td,
.table.table-sm th {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.list-group-item {
  margin-top: 0;
  margin-bottom: 0;
}

/* ----------------------------------------------------------------------------
  ASC Default stuff
----------------------------------------------------------------------------- */
.price {
  display: inline-block;
  padding: 0;
  width: auto;
  height: auto;
  color: #444;
  text-align: center;
  font: bold 0 "Trebuchet MS", "Droid Serif", Arial, sans-serif;
  overflow: visible;
  text-shadow: 0 0 40px #333;
  vertical-align: middle;
  color: transparent;
}

.price.static {
  color: #444;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
}

.price.shown {
  color: #444;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
  transition: color ease-in-out 0.1s, text-shadow ease-in-out 0.1s;
}

.price.ribbon {
  margin-top: -6px;
  margin-bottom: 0;
  padding: 28px 0 0 0;
  width: 140px;
  height: 93px;
  color: #fff;
  background: url(../images/price_bg.png) no-repeat 0 0;
  text-shadow: 0 0 25px #fff;
  color: transparent;
}

.price.burst {
  padding: 60px 0 0 0;
  width: 155px;
  height: 155px;
  color: #fff;
  background: url(../images/price_bg_burst.png) no-repeat 50% 50%;
  text-shadow: 0 0 25px #fff;
  color: transparent;
}

.price.ribbon.static,
.price.burst.static {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.price.ribbon.shown,
.price.burst.shown {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  transition: color ease-in-out 0.1s, text-shadow ease-in-out 0.1s;
}

.price span.dollar-sign,
.price span.cents,
.price span.cents-fees,
.price span.only {
  display: inline-block;
  margin: 5px 0 0 0;
  font-size: 30px;
  line-height: 30px;
  vertical-align: top;
}

.price span.only {
  margin-bottom: 5px;
  font-size: 26px;
  display: block;
}

.price span.dollars,
.price span.dollars-fees {
  display: inline-block;
  margin-left: -1px;
  font-size: 50px;
  line-height: 50px;
  letter-spacing: -1px;
  vertical-align: middle;
}

.price span.cents,
.price span.cents-fees {
  margin: 4px 0 0 3px;
  letter-spacing: -1px;
}

.price.shown span.reg-price {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  text-decoration: line-through;
  padding-left: 3px;
  vertical-align: middle;
  text-shadow: none;
}

.price.ribbon span.reg-price {
  color: #eee;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.price span.dollars[data-pid]:after,
.price span.dollars-fees[data-pid]:after,
.price span.cents[data-pid]:after,
.price span.cents-fees[data-pid]:after,
.price span.dollars[data-pkgid]:after,
.price span.cents[data-pkgid]:after {
  display: inline-block;
  content: "##";
}

.price.shown span.dollars[data-pid]:after,
.price.shown span.dollars-fees[data-pid]:after,
.price.shown span.cents[data-pid]:after,
.price.shown span.cents-fees[data-pid]:after,
.price.shown span.dollars[data-pkgid]:after,
.price.shown span.cents[data-pkgid]:after {
  display: none;
  filter: blur(0);
}

/* -- for dynamic LGAs -- */
.lga-item {
  display: none;
  opacity: 0;
}
.lga-item.lga-replaced {
  display: inline;
  opacity: 1;
}

/* ----------------------------------------------------------------------------
  Accessibility styles
----------------------------------------------------------------------------- */
:is(nav) :is(ul) :focus-visible,
:is(.dropdown-list) :is(ul) :focus-visible {
  outline: 2px solid #007aff !important;
  outline-offset: -2px;
}

/* -- Customize Bootstrap Utilities-- */
.w-auto {
  max-width: 100% !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: 100% !important;
  }
  .w-sm-5 {
    width: 5% !important;
  }
  .w-sm-10 {
    width: 10% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-45 {
    width: 45% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-90 {
    width: 90% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
}
@media (min-width: 768px) {
  .w-md-auto {
    width: 100% !important;
  }
  .w-md-5 {
    width: 5% !important;
  }
  .w-md-10 {
    width: 10% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-45 {
    width: 45% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
}
@media (min-width: 992px) {
  .w-lg-auto {
    width: 100% !important;
  }
  .w-lg-5 {
    width: 5% !important;
  }
  .w-lg-10 {
    width: 10% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-45 {
    width: 45% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-auto {
    width: 100% !important;
  }
  .w-xl-5 {
    width: 5% !important;
  }
  .w-xl-10 {
    width: 10% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-45 {
    width: 45% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-90 {
    width: 90% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
}
@media (min-width: 1400px) {
  .w-xxl-auto {
    width: 100% !important;
  }
  .w-xxl-5 {
    width: 5% !important;
  }
  .w-xxl-10 {
    width: 10% !important;
  }
  .w-xxl-20 {
    width: 20% !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-30 {
    width: 30% !important;
  }
  .w-xxl-40 {
    width: 40% !important;
  }
  .w-xxl-45 {
    width: 45% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-60 {
    width: 60% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-80 {
    width: 80% !important;
  }
  .w-xxl-90 {
    width: 90% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.ls-10 {
  letter-spacing: 0.1rem;
}

.ls-15 {
  letter-spacing: 0.15rem;
}

.ls-20 {
  letter-spacing: 0.2rem;
}

.ls-25 {
  letter-spacing: 0.25rem;
}

.lh-11 {
  line-height: 1.1;
}

.lh-12 {
  line-height: 1.2;
}

.lh-13 {
  line-height: 1.3;
}

.lh-14 {
  line-height: 1.4;
}

.lh-16 {
  line-height: 1.6;
}

.lh-20 {
  line-height: 2;
}

.lh-25 {
  line-height: 2.5;
}

/* ----------------------------------------------------------------------------
  Navigation Menu
----------------------------------------------------------------------------- */
#nav-wrap {
  background: #0b1239;
}

#menutoggle {
  display: block;
  margin: 0;
  padding: 0 15px;
  width: auto;
  color: #0b1239;
  background: transparent;
  height: 50px;
  font-size: 1.1rem;
  font-family: "Montserrat", var(--font-family);
  font-weight: 600;
  line-height: 46px;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  right: 0;
  border-radius: 5px;
  border: 0;
  transition: background 0.2s linear 0s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
#menutoggle span {
  display: none;
  margin-left: 10px;
  vertical-align: middle;
}
#menutoggle .hamburger-box {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 24px;
  vertical-align: middle;
}
#menutoggle .hamburger-box .hamburger-inner {
  display: block;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 75ms;
}
#menutoggle .hamburger-box .hamburger-inner,
#menutoggle .hamburger-box .hamburger-inner::after,
#menutoggle .hamburger-box .hamburger-inner::before {
  position: absolute;
  width: 30px;
  height: 3px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform, width;
  border-radius: 4px;
  background-color: #0b1239;
  right: 0;
}
#menutoggle .hamburger-box .hamburger-inner {
  top: 0;
  width: 25px;
}
#menutoggle .hamburger-box .hamburger-inner::after {
  width: 22px;
}
#menutoggle .hamburger-box .hamburger-inner::after,
#menutoggle .hamburger-box .hamburger-inner::before {
  display: block;
  content: "";
}
#menutoggle .hamburger-box .hamburger-inner::before {
  top: 10px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform, opacity;
}
#menutoggle .hamburger-box .hamburger-inner::after {
  top: 20px;
}
#menutoggle.is-active .hamburger-inner,
#menutoggle.is-active .hamburger-inner::after,
#menutoggle.is-active .hamburger-inner::before {
  width: 30px;
}
#menutoggle.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
#menutoggle.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}
#menutoggle.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}
#menutoggle:hover {
  background-color: #e7f3ea;
}
#menutoggle:hover .hamburger-box .hamburger-inner,
#menutoggle:hover .hamburger-box .hamburger-inner::after,
#menutoggle:hover .hamburger-box .hamburger-inner::before {
  width: 30px;
}
#menutoggle.is-active + #nav ul {
  padding-top: 5px;
  max-height: 1000px;
  opacity: 1;
  transition: max-height 0.3s ease-in-out, opacity 0.25s ease-in-out, padding-top 0.25s ease-in-out;
}

#nav {
  clear: both;
  margin: 0;
  width: 100%;
  height: 0;
  font-family: "Montserrat", var(--font-family);
  overflow: visible;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1000;
}
#nav > ul {
  visibility: hidden;
}
#nav ul {
  float: none;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  opacity: 0;
  max-height: 0;
  background: #fff;
  overflow: hidden;
  transition: max-height 0.25s cubic-bezier(0, 0.7, 0, 1), opacity 0.2s ease-in-out, padding-top 0.25s ease-in-out;
  border-radius: 0;
  bottom: 0;
  right: 0;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  position: relative;
}
#nav ul.submenu {
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 0;
  background: none;
  opacity: 1;
  position: relative;
  top: 0;
  transition: max-height 0.15s linear 0s;
}
#nav ul.submenu a {
  padding-left: 40px;
  background: none;
}
#nav ul.submenu a:before {
  display: inline-block;
  margin-right: 5px;
  margin-left: -15px;
  width: 10px;
  height: inherit;
  content: "»";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}
#nav li {
  display: block;
  margin: 0;
  padding: 0;
  height: auto;
  border: 0;
  border-top: 1px dotted #a1d3b0;
}
#nav li.onmobile {
  display: block;
}
#nav a,
#nav span.submenu {
  float: none;
  display: block;
  margin: 0;
  padding: 9px 20px;
  height: auto;
  color: #0b1239;
  line-height: 1.6em;
  text-align: left;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: background 0.17s linear 0s;
}
#nav span.submenu:after,
#nav a.submenu:after {
  float: right;
  margin-left: 10px;
  width: 15px;
  height: inherit;
  content: "\e817";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#nav span.submenu.is-active,
#nav a.submenu.is-active {
  border-bottom: 1px dotted #55af70;
}
#nav span.submenu.is-active + ul.submenu,
#nav a.submenu.is-active + ul.submenu {
  max-height: 360px;
  overflow-y: auto;
}
#nav span.submenu.is-active:after,
#nav a.submenu.is-active:after {
  content: "\e816";
}
#nav a:hover,
#nav a.is-active,
#nav li:hover span.submenu,
#nav li:hover a.submenu,
#nav ul.submenu a:hover,
#nav ul.submenu a.is-active {
  background: #e7f3ea;
}

@media (min-width: 36em) {
  /* Small devices (landscape phones, 576px and up) */
  /*@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) */
  #menutoggle {
    padding: 0 15px 0 15px;
  }
  #menutoggle span {
    display: inline-block;
  }
  #menutoggle:before {
    margin-right: 10px;
  }
}
@media (min-width: 48em) {
  /* Medium devices (tablets, 768px and up) */
  #nav ul {
    width: calc(100% + 36px);
    left: -18px;
  }
  #nav a,
  #nav span.submenu {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 1060px) {
  /* Large devices (desktops, 992px and up) */
  #menutoggle {
    display: none;
  }
  #nav {
    width: auto;
    height: 45px;
    background: transparent;
    position: relative;
    z-index: 99;
    border: 0;
    border-radius: 4px;
  }
  #nav > ul {
    visibility: visible;
  }
  #nav ul {
    float: left;
    width: 100%;
    height: auto;
    max-height: 45px;
    opacity: 1;
    margin: 4px 0;
    padding-top: 0;
    position: relative;
    z-index: 10;
    top: 0;
    left: 0;
    overflow: visible;
    background-color: transparent;
    box-shadow: none;
  }
  #nav ul.submenu {
    float: left;
    margin: 0;
    padding: 0;
    width: 250px;
    max-height: 0;
    position: absolute;
    opacity: 0;
    background: #111c59;
    top: 95%;
    z-index: 10;
    border-radius: 0 3px 3px 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    transition: max-height 0.2s linear 0s, padding 0.2s linear 0s, opacity 0.2s linear 0s;
    overflow: hidden;
    overflow-y: auto;
  }
  #nav ul.submenu li {
    float: none;
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    height: auto;
  }
  #nav ul.submenu li:first-child a {
    border: 0;
  }
  #nav ul.submenu a {
    float: none;
    display: block;
    margin: 0;
    padding: 7px 15px 7px 28px;
    height: auto;
    text-transform: none;
    text-align: left;
    line-height: 1.5em;
    border-radius: 0;
    border: 0;
    border-top: 1px dotted #0b1239;
  }
  #nav li {
    float: left;
    margin: 0 10px;
    padding: 0;
    height: 37px;
    width: auto;
    min-width: auto;
    position: relative;
    border: 0;
    overflow: visible;
  }
  #nav li:first-child {
    margin-left: 0;
    border-left: 0;
  }
  #nav li:last-child {
    margin-right: 0;
  }
  #nav li.last {
    border-right: 0;
  }
  #nav li.right {
    float: right;
    margin-right: 0;
    border-right: 0;
  }
  #nav li.onmobile {
    display: none;
  }
  #nav li:hover {
    z-index: 11;
  }
  #nav a,
  #nav span.submenu {
    float: left;
    margin: 0;
    padding: 0 2px;
    height: 37px;
    border: 0;
    color: #0b1239;
    text-shadow: none;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 39px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 3px;
    transition: background 0.2s ease-in-out 0s, border 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;
    cursor: pointer;
    border-radius: 0;
    position: relative;
  }
  #nav a::after,
  #nav span.submenu::after {
    bottom: 0;
    left: 50%;
    position: absolute;
    content: "";
    height: 2px;
    width: 0;
    background-color: #0b1239;
    transition: left 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s;
  }
  #nav a.login {
    padding: 0 14px;
    border: 2px solid #0b1239;
    border-radius: 50px;
    line-height: 35px;
    text-transform: uppercase;
  }
  #nav a.login::after {
    display: none;
  }
  #nav a.login:hover {
    color: #fff;
    background-color: #0b1239;
  }
  #nav span.submenu:after,
  #nav a.submenu:after {
    display: none;
  }
  #nav a:hover,
  #nav a.is-active,
  #nav li:hover span.submenu,
  #nav li:hover a.submenu,
  #nav li:hover a.submenu,
  #nav ul.submenu a:hover,
  #nav span.submenu.is-active,
  #nav a.submenu.is-active {
    background: transparent;
    position: relative;
  }
  #nav a:hover::after,
  #nav a.is-active::after,
  #nav li:hover span.submenu::after,
  #nav li:hover a.submenu::after,
  #nav li:hover a.submenu::after,
  #nav ul.submenu a:hover::after,
  #nav span.submenu.is-active::after,
  #nav a.submenu.is-active::after {
    position: absolute;
    content: "";
    left: 0;
    height: 2px;
    width: 100%;
  }
  #nav li:hover a.submenu,
  #nav li:hover span.submenu,
  #nav li:hover a.submenu,
  #nav span.submenu.is-active.hover,
  #nav a.submenu.is-active.hover {
    border-radius: 3px 3px 0 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  #nav li:hover ul.submenu,
  #nav ul.submenu.is-active {
    padding: 3px 0;
    left: 0;
    max-height: 360px;
    opacity: 1;
    display: block !important;
    visibility: visible !important;
  }
  #nav ul.submenu li a:hover,
  #nav ul.submenu li a.is-active {
    background: #0b1239;
  }
}
/* ----------------------------------------------------------------------------
	Buttons
----------------------------------------------------------------------------- */
@keyframes btnProcessing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cta-btn {
  display: inline-block;
  padding: 10px 0 8px 0;
  color: #fff;
  background-color: #555555;
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 220px;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Montserrat", var(--font-family);
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;
  vertical-align: middle;
  overflow: hidden;
  transition: color 0.15s ease-in-out 0.03s, background-color 0.1s ease-in-out 0.05s;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  text-decoration: none;
}
.cta-btn.wide {
  max-width: 270px;
}
.cta-btn:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #3c3c3c 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.3s, opacity 0.5s;
}
.cta-btn:active {
  transform: translateY(1px);
}
.cta-btn:active:after {
  transform: scale(0, 0);
  opacity: 0.2;
  transition: 0s;
}

.cta-btn.fancy {
  background: #555555;
  background-image: linear-gradient(#555555, #434343);
}

.cta-btn:hover,
.cta-btn:active {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #3f3f3f;
  border: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.cta-btn.big {
  padding: 13px 0 10px 0;
}

.cta-btn.big.outline {
  padding: 12px 0 9px 0;
}

.cta-btn.small {
  padding: 6px 0 6px 0;
  font-size: 1rem;
}

.cta-btn.small.fancy {
  line-height: 41px;
}

.cta-btn.small.outline {
  padding: 6px 0 5px 0;
}

.cta-btn.dark {
  color: #fff;
  background: #555555;
}

.cta-btn.white {
  color: #333;
  background: #fff;
}

.cta-btn.orange {
  color: #fff;
  background: #ff571f;
}

.cta-btn.yellow {
  color: #333;
  background: #ffc107;
}

.cta-btn.green {
  color: #fff;
  background: #46955e;
}

.cta-btn.red {
  color: #fff;
  background: #f43325;
}

.cta-btn.blue {
  color: #fff;
  background: #0b1239;
}

.cta-btn[class~=outline] {
  border: 1px solid #555555;
}

.cta-btn.outline {
  color: #555555;
  background: transparent;
  border-color: #555555;
  border-width: 2px;
}

.cta-btn.dark.outline {
  color: #555555;
  background: transparent;
  border-color: #555555;
}

.cta-btn.white.outline {
  color: #fff;
  background: transparent;
  border-color: #fff;
}

.cta-btn.orange.outline {
  color: #ff571f;
  background: transparent;
  border-color: #ff571f;
}

.cta-btn.green.outline {
  color: #46955e;
  background: transparent;
  border-color: #46955e;
}

.cta-btn.red.outline {
  color: #f43325;
  background: transparent;
  border-color: #f43325;
}

.cta-btn.yellow.outline {
  color: #ffc107;
  background: transparent;
  border-color: #ffc107;
}

.cta-btn.blue.outline {
  color: #0b1239;
  background: transparent;
  border-color: #0b1239;
}

.cta-btn.pill {
  border-radius: 100px;
}

.cta-btn.dark.fancy {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  background: #555555;
  background-image: linear-gradient(#555555, #434343);
}

.cta-btn.dark:hover,
.cta-btn.dark:active {
  background: #3f3f3f;
}

.cta-btn.outline:hover,
.cta-btn.outline:active,
.cta-btn.dark.outline:hover,
.cta-btn.dark.outline:active {
  background: rgba(85, 85, 85, 0.1);
}

.cta-btn.white:hover,
.cta-btn.white:active {
  background: #ededed;
}

.cta-btn.white.outline:hover,
.cta-btn.white.outline:active {
  color: #333;
  background: #fff;
}

.cta-btn.orange.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  background: #ff571f;
  background-image: linear-gradient(#ff571f, #fa3f00);
}

.cta-btn.orange:hover,
.cta-btn.orange:active {
  background: #f33d00;
}

.cta-btn.orange.outline:hover,
.cta-btn.orange.outline:active {
  background: rgba(255, 87, 31, 0.1);
}

.cta-btn.green.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #46955e;
  background-image: linear-gradient(#46955e, #3b7d4f);
  color: #fff;
}

.cta-btn.green:hover,
.cta-btn.green:active {
  background: #38784b;
}

.cta-btn.green.outline:hover,
.cta-btn.green.outline:active {
  background: #46955e;
  color: #fff;
}

.cta-btn.red.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #f43325;
  background-image: linear-gradient(#f43325, #ea1b0c);
}

.cta-btn.red:hover,
.cta-btn.red:active {
  background: #e21a0b;
}

.cta-btn.red.outline:hover,
.cta-btn.red.outline:active {
  background: rgba(244, 51, 37, 0.1);
}

.cta-btn.blue.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #0b1239;
  background-image: linear-gradient(#0b1239, #05091b);
}

.cta-btn.blue:hover,
.cta-btn.blue:active {
  background: #040715;
}

.cta-btn.blue.outline:hover,
.cta-btn.blue.outline:active {
  background: #0b1239;
  color: #fff;
}

.cta-btn.yellow.fancy {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background: #ffc107;
  background-image: linear-gradient(#ffce3a, #edb100);
}

.cta-btn.yellow:hover,
.cta-btn.yellow:active {
  background: #ffca2b;
}

.cta-btn.yellow.outline:hover,
.cta-btn.yellow.outline:active {
  background: rgba(255, 193, 7, 0.1);
}

/* ----------------------------------------------------------------------------
  Certus Buttons
----------------------------------------------------------------------------- */
.scroll-anim {
  opacity: 0;
  position: relative;
}

.fade-in {
  animation: fadeInAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.slide-in-top {
  animation: slideInTopAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideInTopAnimation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  75% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
}
.slide-in-bottom {
  animation: slideInBottomAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideInBottomAnimation {
  0% {
    opacity: 0;
    transform: translateY(200%);
  }
  75% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
}
.slide-in-left {
  animation: slideInLeftAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideInLeftAnimation {
  0% {
    opacity: 0;
    transform: translateX(-75%);
  }
  75% {
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}
.slide-in-right {
  animation: slideInRightAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideInRightAnimation {
  0% {
    opacity: 0;
    transform: translateX(200%);
  }
  75% {
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  color: #333333;
}

body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  background-color: #fff;
  color: #333333;
}

.wrap {
  padding: 30px 0;
  overflow-x: clip;
}

.container-fluid.but-fixed {
  max-width: 1356px;
}
.container-fluid.wider {
  max-width: 1800px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: #0b1239 !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
}

#header-wrap {
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

#header a.logo {
  border: 0;
  width: 100%;
  max-width: 200px;
  min-width: 200px;
}
#header a.logo img {
  width: auto;
  height: auto;
}
#header a.logo span.big,
#header a.logo span.small {
  font-size: 1.5em;
  color: #555;
  line-height: 1em;
}
#header a.logo span.big {
  font-weight: 600;
  font-family: var(--font-family);
}
#header a.logo span.small {
  font-size: 16px;
  font-family: var(--font-family);
}

#footer-wrap {
  background-color: #0b1239;
}

.footer {
  width: 100%;
  background-color: transparent;
  color: #fff;
  font-size: 1rem;
}
.footer p.trustmark {
  display: none;
  margin: 0;
}
.footer a {
  color: inherit;
}
.footer .social a {
  border: 0;
}
.footer img {
  display: inline-block;
}
.footer ul.links {
  padding-left: 0;
  list-style: none;
}
.footer ul.links li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.footer a.social {
  height: 40px;
  width: 40px;
  margin: 0 5px;
  color: #0b1239 !important;
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 19px;
}
.footer a.social [class^=icon-]:before {
  line-height: 41px;
}
.footer a.social:hover,
.footer a.social:active {
  font-size: 21px;
}

/* --------------------------------------------------------------
    Patterned BGs
-------------------------------------------------------------- */
.bg-burst-pattern {
  background-image: url(/images/burst-pattern-bg.png);
  background-repeat: repeat;
  background-size: 150px;
  background-position: center center;
}

.bg-squiggly-outline-pattern {
  background-image: url(/images/squiggly-outline-pattern-bg.png);
  background-repeat: repeat;
  background-position: center center;
}

.bg-squiggly-outline-dark-pattern {
  background-image: url(/images/squiggly-outline-dark-pattern-bg.png);
  background-repeat: repeat;
  background-position: center center;
}

.bg-squiggly-filled-pattern {
  background-image: url(/images/squiggly-filled-pattern-bg.png);
  background-repeat: repeat;
  background-position: center center;
}

#footer-cta-wrap {
  position: relative;
  color: #fff;
}
#footer-cta-wrap .footer-cta-box {
  margin-bottom: -100px;
  background-color: #46955e;
}

#footer-cta-wrap + #footer-wrap {
  padding-top: 170px !important;
}

#banner-wrap * {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}
#banner-wrap.homepage {
  background: #0b1239 url(/images/certus-icon-bg.png) no-repeat;
  background-position: calc(100% + 50px) -100px;
  background-size: 400px;
}

#image-carousel-wrap {
  position: relative;
  background-color: #0b1239;
}
#image-carousel-wrap .image-carousel {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}
#image-carousel-wrap .image-carousel.ready {
  max-height: 500px;
  opacity: 1;
}
#image-carousel-wrap:before, #image-carousel-wrap:after {
  position: absolute;
  content: "";
  height: 70px;
  width: 100%;
  top: -1px;
  left: 0;
  z-index: 100;
  clip-path: ellipse(55% 60% at 50% 0%);
  background-color: #0b1239;
  opacity: 0;
  transition: opacity ease-in-out 0.03s;
}
#image-carousel-wrap:after {
  top: auto;
  bottom: -1px;
  clip-path: ellipse(55% 60% at 50% 100%);
}
#image-carousel-wrap.ready:before, #image-carousel-wrap.ready:after {
  opacity: 1;
}

.vertical-card {
  background-color: #0b1239;
  position: relative;
  overflow: hidden;
}
.vertical-card .card-copy {
  z-index: 1;
}
.vertical-card:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.vertical-card.workplace {
  background: #0b1239 url(/images/carousel/clapping-workers.jpg) no-repeat 30% 20%/cover;
}
.vertical-card.workplace:before {
  background-color: #0b1239;
  opacity: 0.9;
}
.vertical-card.technical {
  background: #0a2d50 url(/images/carousel/team-training.jpg) no-repeat 30% 30%/cover;
}
.vertical-card.technical:before {
  background-color: #0a2d50;
  opacity: 0.95;
}
.vertical-card.professional {
  background: #045475 url(/images/carousel/team-planning.jpg) no-repeat 30% 10%/cover;
}
.vertical-card.professional:before {
  background-color: #045475;
  opacity: 0.95;
}
.vertical-card.driver {
  background: #007393 url(/images/carousel/teen-driver.jpg) no-repeat 30% 30%/cover;
}
.vertical-card.driver:before {
  background-color: #007393;
  opacity: 0.95;
}

.press-release-card .thumbnail {
  display: block;
  overflow: hidden;
}
.press-release-card .thumbnail img.zoom {
  transition: transform 0.2s;
}
.press-release-card .thumbnail:hover img.zoom {
  transform: scale(1.025);
}

.copy-column {
  position: relative;
  z-index: 1;
}

.squiggly-graphic {
  position: relative;
}
.squiggly-graphic > * {
  position: relative;
  z-index: 1;
}
.squiggly-graphic:before {
  display: none;
  width: 699px;
  height: 172px;
  position: absolute;
  content: url(/images/squiggly-graphic.svg);
  z-index: 0;
}
.squiggly-graphic.top-right:before {
  top: -100px;
  left: 25%;
}
.squiggly-graphic.bottom-right:before {
  bottom: -105px;
  left: 0;
}
.squiggly-graphic.bottom-left:before {
  bottom: -110px;
  right: -50px;
}
.squiggly-graphic.bottom:before {
  bottom: -110px;
  left: -100px;
}

.burst-graphic {
  position: relative;
}
.burst-graphic > * {
  position: relative;
  z-index: 1;
}
.burst-graphic:before {
  display: none;
  position: absolute;
  content: url(/images/burst-graphic.svg);
  z-index: 0;
}
.burst-graphic.top-left:before {
  top: -100px;
  left: -100px;
}
.burst-graphic.top-right:before {
  top: -100px;
  right: -100px;
}
.burst-graphic.bottom-left:before {
  bottom: -100px;
  left: -100px;
}
.burst-graphic.bottom-right:before {
  bottom: -100px;
  right: -100px;
}

.bg-green-icon {
  background: #0b1239 url(/images/certus-icon-bg.png) no-repeat;
  background-position: calc(100% + 150px) 50%;
  background-size: 500px;
}
.bg-green-icon .bg-green-icon-small {
  background-size: 500px;
}

#case-studies-wrap {
  background-color: #f1f1f1;
}

.timeline-wrap {
  position: relative;
}
.timeline-wrap > * {
  z-index: 1;
}
.timeline-wrap::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 50vw;
  top: 0;
  left: calc(-50vw + 15px);
  background-color: #d8f793;
  z-index: 0;
}
.timeline-wrap .timeline-year {
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  z-index: 1;
}
.timeline-wrap .timeline-year::after {
  position: absolute;
  content: "";
  left: -25px;
  bottom: -3px;
  width: calc(100% + 35px);
  height: 3px;
  background-color: #d8f793;
}

.burst-row-wrap {
  position: relative;
}
.burst-row-wrap > * {
  z-index: 1;
}
.burst-row-wrap::before {
  display: none;
  position: absolute;
  content: "";
  height: 100%;
  width: 50vw;
  top: 0;
  left: calc(-50vw + 33.3333%);
  background-color: #0b1239;
  z-index: 0;
}
.burst-row-wrap .graphic {
  background-color: #0b1239;
  background-image: url(/images/certus-icon-bg-vertical.png);
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: 500px;
}
.burst-row-wrap .graphic img {
  display: block;
  margin: 0 auto;
  max-width: 250px;
  transform: translateY(50%);
}
.burst-row-wrap.featured-news::before {
  background-color: #d8f793;
}
.burst-row-wrap.featured-news .graphic {
  background-color: #d8f793;
}
.burst-row-wrap .copy {
  text-align: center;
}

.slider-overflow-wrap .tns-ovh {
  overflow: visible;
}
.slider-overflow-wrap .tns-item {
  display: flex;
}
.slider-overflow-wrap .slide-card {
  width: 100%;
}
.slider-overflow-wrap .slide-card .slide-image {
  min-width: 200px;
}
.slider-overflow-wrap .slide-card .slide-image img {
  max-width: 200px;
}

#brands-wrap .brand-card {
  min-height: 100px;
  position: relative;
}
#brands-wrap .brand-card .brand-logo {
  cursor: pointer;
  filter: grayscale(1) opacity(0.75);
}
#brands-wrap .brand-card .brand-logo:hover {
  filter: none;
}
#brands-wrap .brand-card:focus-visible .brand-logo {
  filter: none;
}
#brands-wrap .brand-card .brand-info {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  opacity: 0;
  background-color: #fff;
  z-index: 10;
  transform: translateY(-50%) translateX(-50%) scale(0);
  transition: opacity ease-in-out 0.5s, transform ease-in-out 0.3s;
}
#brands-wrap .brand-card .brand-info .brand-logo {
  text-align: left;
  filter: none;
}
#brands-wrap .brand-card .brand-info:focus-visible {
  outline: 2px solid #316ce8;
}
#brands-wrap .brand-card.is-active .brand-info {
  opacity: 1;
  transform: translateY(-50%) translateX(-50%) scale(1);
}

.pillars-slider-wrap {
  padding-bottom: clamp(200px, 50%, (50% - 30px) / 2) !important;
  position: relative;
  user-select: none;
}
.pillars-slider-wrap + .pillar-image {
  position: relative;
  display: block;
  width: 400px;
  max-width: calc(100% - 30px);
  transform: translateY(-50%);
  margin-bottom: max(-40%, -150px);
}

#news-banner-image {
  background-size: cover;
  background-position: center;
}

.leader-card:hover {
  cursor: pointer;
}
.leader-card:hover .leader-photo > img {
  transform: scale(1.05);
}
.leader-card:hover .leader-photo::after {
  opacity: 1;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.leader-card:hover .leader-photo::before {
  opacity: 0.1;
  transform: translateY(-50%) translateX(-50%) scale(1);
}
.leader-card .leader-photo {
  position: relative;
  max-width: 200px;
}
.leader-card .leader-photo > img {
  position: relative;
  z-index: 1;
  transition: transform ease-in-out 0.2s;
}
.leader-card .leader-photo::after {
  position: absolute;
  font-family: "icons";
  content: "\e824";
  display: block;
  width: 45px;
  height: 45px;
  right: 10px;
  bottom: 10px;
  line-height: 45px;
  background-color: #d8f793;
  border-radius: 50px;
  opacity: 1;
  transition: opacity ease-in-out 0.3s, box-shadow ease-in-out 0.3s;
  z-index: 1;
}
.leader-card .leader-photo::before {
  position: absolute;
  content: "";
  width: calc(100% + 100px);
  height: calc(100% + 100px);
  border-radius: 50rem;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0);
  background-color: #079bd8;
  opacity: 0.1;
  transition: transform ease-in-out 0.3s;
  z-index: 0;
}

#find-course-form .custom-select {
  border-width: 2px;
}
#find-course-form .submit-btn {
  border-width: 2px;
}

#acton-form {
  width: 100%;
  max-width: 600px;
  min-height: 550px;
}
#acton-form .ao-form-field {
  border-radius: 0.375rem;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
@media (min-width: 576px) {
  #header a.logo {
    max-width: 200px;
  }
  .squiggly-graphic:before,
  .burst-graphic:before {
    display: block;
  }
  .image-column img {
    min-width: 360px;
  }
}
@media (min-width: 768px) {
  [class*=container-] {
    padding-right: var(--bs-gutter-x, 1.5rem);
    padding-left: var(--bs-gutter-x, 1.5rem);
  }
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    max-width: 250px;
  }
  #banner-wrap.homepage {
    background-position: calc(100% + 50px) -100px;
    background-size: 500px;
  }
  #image-carousel-wrap:before, #image-carousel-wrap:after {
    height: 75px;
    clip-path: ellipse(52% 60% at 50% 0%);
  }
  #image-carousel-wrap:after {
    clip-path: ellipse(52% 60% at 50% 100%);
  }
  .bg-green-icon {
    background-position: calc(100% + 150px) 50%;
    background-size: 600px;
  }
  .timeline-wrap::before {
    left: calc(-50vw + 33.3333%);
  }
  .timeline-wrap .timeline-year {
    display: block;
    margin-bottom: calc(-1em - 1.7rem);
    text-align: right;
    transform: translateX(calc(-100% - 3rem));
  }
  .timeline-wrap .timeline-year::after {
    display: none;
  }
  .timeline-wrap .month {
    padding-top: 1rem;
    display: inline-block;
    position: relative;
  }
  .timeline-wrap .month::before {
    position: absolute;
    content: "";
    top: -3px;
    left: -3rem;
    width: calc(100% + 3rem);
    height: 3px;
    background-color: #d8f793;
  }
  .timeline-wrap .col-lg-6:not(:nth-of-type(1)) .month::before {
    display: none;
  }
  .burst-row-wrap::before {
    display: block;
    background-image: url(/images/certus-icon-bg-horizontal.png);
    background-repeat: no-repeat;
    background-position: 100% 50%;
    background-size: 260px;
  }
  .burst-row-wrap .graphic {
    background: none !important;
  }
  .burst-row-wrap .graphic img {
    max-width: 250px;
    transform: translateY(0) translateX(50%);
  }
  .burst-row-wrap .copy {
    text-align: left;
    padding-left: calc(150px + 2rem) !important;
  }
  .form-row-wrap {
    position: relative;
  }
  .form-row-wrap > * {
    z-index: 1;
  }
  .form-row-wrap::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 50vw;
    top: 0;
    left: calc(-50vw + 41.66666667%);
    background-color: #0b1239;
    z-index: 0;
  }
  #brands-wrap .brand-card {
    min-height: 200px;
  }
  .pillars-slider-wrap {
    margin-bottom: 0;
    padding-bottom: 30px !important;
  }
  .pillars-slider-wrap + .pillar-image {
    position: absolute;
    right: calc(var(--bs-gutter-x) * 0.5);
    top: 50%;
    width: 35%;
    max-width: calc(100% - 30px);
    transform: translateY(-50%);
    margin-bottom: 0;
  }
  .image-column img {
    min-width: 400px;
  }
}
@media (min-width: 992px) {
  [class*=container-] {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  #banner-wrap.homepage {
    background-position: calc(100% + 50px) -100px;
    background-size: 600px;
  }
  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }
  #image-carousel-wrap:before, #image-carousel-wrap:after {
    height: 75px;
    clip-path: ellipse(52% 70% at 50% 0%);
  }
  #image-carousel-wrap:after {
    clip-path: ellipse(52% 70% at 50% 100%);
  }
  .timeline-wrap .timeline-year {
    margin-bottom: calc(-1em - 1.5rem);
  }
  .timeline-wrap .col-lg-6:not(:nth-of-type(1), :nth-of-type(2)) .month::before {
    display: none;
  }
  .timeline-wrap .col-lg-6:where(:nth-of-type(2)) .month::before {
    display: block !important;
    left: -30vw;
    width: calc(100% + 30vw);
    z-index: 0;
  }
  #brands-wrap .brand-card {
    min-height: 250px;
  }
  .pillars-slider-wrap {
    padding-bottom: 3rem !important;
  }
  #news-banner-image {
    min-height: 300px;
  }
}
@media (min-width: 1200px) {
  #header a.logo {
    max-width: 280px;
  }
  #banner-wrap.homepage {
    background-position: calc(100% + 40px) -100px;
    background-size: 600px;
  }
  #image-carousel-wrap:before, #image-carousel-wrap:after {
    height: 75px;
    clip-path: ellipse(52% 80% at 50% 0%);
  }
  #image-carousel-wrap:after {
    clip-path: ellipse(52% 80% at 50% 100%);
  }
  .burst-row-wrap::before {
    background-size: 320px;
  }
  .burst-row-wrap .graphic img {
    max-width: 300px;
  }
  .burst-row-wrap .copy {
    padding-left: calc(200px + 2rem) !important;
  }
  .pillars-slider-wrap + .pillar-image {
    width: 30%;
  }
  #news-banner-image {
    min-height: 350px;
  }
}
@media (min-width: 1400px) {
  #banner-wrap.homepage {
    background-position: calc(100% + 40px) 101%;
    background-size: 700px;
  }
  #image-carousel-wrap:before, #image-carousel-wrap:after {
    height: 75px;
    clip-path: ellipse(52% 80% at 50% 0%);
  }
  #image-carousel-wrap:after {
    clip-path: ellipse(52% 80% at 50% 100%);
  }
}
@media (min-width: 1600px) {
  #banner-wrap.homepage {
    background-position: 95% 101%;
    background-size: 700px;
  }
  #image-carousel-wrap:before, #image-carousel-wrap:after {
    height: 75px;
    clip-path: ellipse(52% 100% at 50% 0%);
  }
  #image-carousel-wrap:after {
    clip-path: ellipse(52% 100% at 50% 100%);
  }
}
@media (min-width: 1900px) {
  #banner-wrap.homepage {
    background-position: 80% 101%;
    background-size: 700px;
  }
  #image-carousel-wrap:before, #image-carousel-wrap:after {
    height: 75px;
    clip-path: ellipse(52% 100% at 50% 0%);
  }
  #image-carousel-wrap:after {
    clip-path: ellipse(52% 100% at 50% 100%);
  }
}