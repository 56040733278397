/* ----------------------------------------------------------------------------
  Navigation Menu
----------------------------------------------------------------------------- */
#nav-wrap {
  background: $theme-primary;
}
#menutoggle {
  display: block;
  margin: 0;
  padding: 0 15px;
  width: auto;
  color: $theme-primary;
  background: transparent;
  height: 50px;
  font-size: 1.1rem;
  font-family: 'Montserrat', var(--font-family);
  font-weight: 600;
  line-height: 46px;
  // text-shadow: 0 1px 0 rgba(0, 0, 0, 0.35);
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  // position: absolute;
  right: 0;
  // top: -23px;
  border-radius: 5px;
  border: 0;
  transition: background 0.2s linear 0s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  span {
    display: none;
    margin-left: 10px;
    vertical-align: middle;
  }
  .hamburger-box {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 24px;
    vertical-align: middle;
    .hamburger-inner {
      display: block;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition-duration: 75ms;
    }
    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      position: absolute;
      width: 30px;
      height: 3px;
      transition-timing-function: ease;
      transition-duration: 0.15s;
      transition-property: transform, width;
      border-radius: 4px;
      background-color: $theme-primary;
      right: 0;
    }
    .hamburger-inner {
      top: 0;
      width: 25px;
    }
    .hamburger-inner::after {
      width: 22px;
    }
    .hamburger-inner::after,
    .hamburger-inner::before {
      display: block;
      content: '';
    }
    .hamburger-inner::before {
      top: 10px;
      transition-timing-function: ease;
      transition-duration: 0.15s;
      transition-property: transform, opacity;
    }
    .hamburger-inner::after {
      top: 20px;
    }
  }
  &.is-active {
    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      width: 30px;
    }
    .hamburger-inner {
      transform: translate3d(0, 10px, 0) rotate(45deg);
    }
    .hamburger-inner::before {
      transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
      opacity: 0;
    }
    .hamburger-inner::after {
      transform: translate3d(0, -20px, 0) rotate(-90deg);
    }
  }
  &:hover {
    background-color: lighten($theme-secondary, 50%);
    .hamburger-box {
      .hamburger-inner,
      .hamburger-inner::after,
      .hamburger-inner::before {
        width: 30px;
      }
    }
  }
  // &:hover,
  // &:active,
  // &.is-active {
  //   background-color: lighten($theme-secondary, 50%);
  //   border-radius: 4px;
  // }
  &.is-active + #nav ul {
    padding-top: 5px;
    // height: 100vh;
    max-height: 1000px;
    opacity: 1;
    transition: max-height 0.3s ease-in-out, opacity 0.25s ease-in-out, padding-top 0.25s ease-in-out;
  }
}
#nav {
  clear: both;
  margin: 0;
  width: 100%;
  height: 0;
  font-family: 'Montserrat', var(--font-family);
  overflow: visible;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1000;
  //border-top: 1px solid $theme-primary;
  & > ul {
    visibility: hidden;
  }
  ul {
    float: none;
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    opacity: 0;
    max-height: 0;
    background: #fff;
    overflow: hidden;
    transition:
      max-height 0.25s cubic-bezier(0, 0.7, 0, 1),
      opacity 0.2s ease-in-out, 
      padding-top 0.25s ease-in-out;
    border-radius: 0;
    bottom: 0;
    right: 0;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    // box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.075);
    // border-top: 1px dotted $theme-primary;
    position: relative;
    &.submenu {
      margin: 0;
      padding: 0;
      width: 100%;
      max-height: 0;
      background: none;
      opacity: 1;
      position: relative;
      top: 0;
      transition: max-height 0.15s linear 0s;
      // &:hover {
      //   display: block !important;
      // }
      a {
        padding-left: 40px;
        background: none;
        &:before {
          display: inline-block;
          margin-right: 5px;
          margin-left: -15px;
          width: 10px;
          height: inherit;
          content: '\00BB';
          color: inherit;
          font-family: 'icons';
          text-align: center;
          font-size: inherit;
          vertical-align: top;
        }
      }
    }
  }
  li {
    display: block;
    margin: 0;
    padding: 0;
    height: auto;
    // overflow: hidden;
    border: 0;
    border-top: 1px dotted lighten($theme-secondary, 30%);
    // &:first-child {
    //   border-top: 0;
    // }
    &.onmobile {
      display: block;
    }
  }
  a,
  span.submenu {
    float: none;
    display: block;
    margin: 0;
    padding: 9px 20px;
    height: auto;
    color: $theme-primary;
    line-height: 1.6em;
    // text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    text-align: left;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    border: 0;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background 0.17s linear 0s;
  }
  span.submenu:after,
  a.submenu:after {
    float: right;
    margin-left: 10px;
    width: 15px;
    height: inherit;
    content: '\e817';
    color: inherit;
    font-family: 'icons';
    text-align: center;
    font-size: 105%;
    vertical-align: top;
  }
  span.submenu.is-active,
  a.submenu.is-active {
    border-bottom: 1px dotted lighten($theme-secondary, 8%);
    + ul.submenu {
      max-height: 360px;
      overflow-y: auto;
    }
  }
  span.submenu.is-active:after,
  a.submenu.is-active:after {
    content: '\e816';
  }
  a:hover,
  a.is-active,
  li:hover span.submenu,
  li:hover a.submenu,
  ul.submenu a:hover,
  ul.submenu a.is-active {
    background: lighten($theme-secondary, 50%);
  }
}

@media (min-width: 36em) {
  /* Small devices (landscape phones, 576px and up) */
  /*@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) */
  #menutoggle {
    padding: 0 15px 0 15px;
    span {
      display: inline-block;
    }
    &:before {
      margin-right: 10px;
    }
  }
}
@media (min-width: 48em) {
  /* Medium devices (tablets, 768px and up) */
  #nav {
    ul {
      width: calc(100% + 36px);
      left: -18px;
    }
    a,
    span.submenu {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
@media (min-width: 1060px) {
  /* Large devices (desktops, 992px and up) */
  #menutoggle {
    display: none;
  }
  #nav {
    width: auto;
    height: 45px;
    background: transparent;
    position: relative;
    z-index: 99;
    border: 0;
    border-radius: 4px;
    & > ul {
      visibility: visible;
    }
    ul {
      // display: block !important;
      float: left;
      width: 100%;
      height: auto;
      max-height: 45px; // added for accessibility menu.js
      opacity: 1;
      margin: 4px 0;
      padding-top: 0;
      position: relative;
      z-index: 10;
      top: 0;
      left: 0;
      overflow: visible;
      background-color: transparent;
      box-shadow: none;
      &.submenu {
        // display: none !important;
        float: left;
        margin: 0;
        padding: 0;
        width: 250px;
        max-height: 0;
        position: absolute;
        opacity: 0;
        background: lighten($theme-primary, 7.5%);
        top: 95%;
        z-index: 10;
        border-radius: 0 3px 3px 3px;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
        transition:
          max-height 0.2s linear 0s,
          padding 0.2s linear 0s,
          opacity 0.2s linear 0s;
        overflow: hidden;
        overflow-y: auto;
        li {
          float: none;
          display: block;
          margin: 0;
          padding: 0;
          border: 0;
          height: auto;
          &:first-child a {
            border: 0;
          }
        }
        a {
          float: none;
          display: block;
          margin: 0;
          padding: 7px 15px 7px 28px;
          height: auto;
          text-transform: none;
          text-align: left;
          line-height: 1.5em;
          border-radius: 0;
          border: 0;
          border-top: 1px dotted $theme-primary;
        }
      }
    }
    li {
      float: left;
      margin: 0 10px;
      padding: 0;
      height: 37px;
      width: auto;
      min-width: auto;
      position: relative;
      border: 0;
      overflow: visible;
      &:first-child {
        margin-left: 0;
        border-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &.last {
        border-right: 0;
      }
      &.right {
        float: right;
        margin-right: 0;
        border-right: 0;
      }
      &.onmobile {
        display: none;
      }
      &:hover {
        z-index: 11;
      }
    }
    a,
    span.submenu {
      float: left;
      margin: 0;
      padding: 0 2px;
      height: 37px;
      border: 0;
      color: $theme-primary;
      text-shadow: none;
      font-weight: 600;
      font-size: 0.9rem;
      line-height: 39px;
      text-align: center;
      text-transform: uppercase;
      border-radius: 3px;
      transition: background 0.2s ease-in-out 0s, border 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;
      cursor: pointer;
      border-radius: 0;
      // border-bottom: 2px solid transparent;
      position: relative;
      &::after {
        bottom: 0;
        left: 50%;
        position: absolute;
        content: '';
        height: 2px;
        width: 0;
        background-color: $theme-primary;
        transition: left 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s;
      }
    }
    a.login {
      padding: 0 14px;
      border: 2px solid $theme-primary;
      border-radius: 50px;
      line-height: 35px;
      text-transform: uppercase;
      &::after {
        display: none;
      }
      &:hover {
        color: #fff;
        background-color: $theme-primary;
      }
    }
    span.submenu:after,
    a.submenu:after {
      display: none;
    }
    a:hover,
    a.is-active,
    li:hover span.submenu,
    li:hover a.submenu,
    li:hover a.submenu,
    ul.submenu a:hover,
    span.submenu.is-active,
    a.submenu.is-active {
      // color: #fff;
      background: transparent;
      // border-bottom: 2px solid $theme-primary;
      position: relative;
      &::after {
        position: absolute;
        content: '';
        left: 0;
        height: 2px;
        width: 100%;
      }
    }
    li:hover a.submenu,
    li:hover span.submenu,
    li:hover a.submenu,
    span.submenu.is-active.hover,
    a.submenu.is-active.hover {
      border-radius: 3px 3px 0 0;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    li:hover ul.submenu,
    ul.submenu.is-active {
      padding: 3px 0;
      left: 0;
      max-height: 360px;
      opacity: 1;
      display: block !important;
      // below overrides the "invisible" class styled applied for accessibility on hover
      visibility: visible !important;
    }
    ul.submenu li a:hover,
    ul.submenu li a.is-active {
      // color: #ffffff;
      background: $theme-primary;
    }
  }
}
